import getUrlParts from './getUrlParts';

const getUsLangHref = (locale, pageHref) => {
  const { base, slug } = getUrlParts(pageHref);
  return locale === 'en-us' ? pageHref : `${base}/${locale}/${slug}`;
};

const getNonUsLangHref = (locale, pageHref, lang) => {
  return locale === lang.toLowerCase()
    ? pageHref
    : pageHref?.replace(`/${lang.toLowerCase()}`, locale === 'en-us' ? '' : `/${locale}`);
};

const getPageHrefByLocale = (locale, pageHref, lang) => {
  let href = pageHref;
  if (locale && pageHref && lang) {
    href =
      lang.toLowerCase() === 'en-us'
        ? getUsLangHref(locale, pageHref)
        : getNonUsLangHref(locale, pageHref, lang);
  }
  return href;
};

export default getPageHrefByLocale;
