'use client';
/* eslint-disable react/prop-types */
import React, { createContext, useContext } from 'react';
import contentMapping from '../../../contentMapping';

const ContentModuleContext = createContext(() => ({}));

export const ContentModuleContextProvider = ({ children }) => {
  return (
    <ContentModuleContext.Provider value={contentMapping}>{children}</ContentModuleContext.Provider>
  );
};

export const useContentModuleContext = () => {
  return useContext(ContentModuleContext);
};

export default ContentModuleContext;
