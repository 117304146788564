import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const AuthContext = createContext({
  signUpStep: 0,
  setSignUpStep: () => undefined,
  authType: 'sign-in',
  setAuthType: () => undefined,
  authUser: null,
  setAuthUser: () => undefined,
  profileExists: false,
  setProfileExists: () => undefined
});

export const useAuthContext = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error('useAuthContext must be used within AuthProvider');
  }

  return auth;
};

export const AuthContextProvider = ({ children }) => {
  const [signUpStep, setSignUpStep] = useState(0);
  const [authType, setAuthType] = useState('sign-in');
  const [authUser, setAuthUser] = useState(null);
  const [profileExists, setProfileExists] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        signUpStep,
        setSignUpStep,
        authType,
        setAuthType,
        authUser,
        setAuthUser,
        profileExists,
        setProfileExists
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
