/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import * as React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import cx from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import styled, { keyframes, css } from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import ErrorBoundary from '../ErrorBoundary';
import getPath from '../../utils/getPath';
import getSEO from '../../utils/getSEO';
import getPathLocale from '../../utils/getPathLocale';
import usePrevious from '../../utils/hooks/usePrevious';
import useUser from '../../utils/hooks/useUser';
import { getSavedRecipes } from '../../store/modules/recipes';
import { initApp } from '../../store/modules/app';

import GlobalFooter from '../GlobalFooter';
import GlobalHeader from '../GlobalHeader';
import getHrefLang, { mapping as locales } from '../../utils/getHrefLang';
import getPageHrefByLocale from '../../utils/getPageHrefByLocale';
import getUrlParts from '../../utils/getUrlParts';
import styles from './Layout.module.scss';
import ContentModule from '../ContentModule';

const SubLayoutNewSite = dynamic(() => import('./SubLayoutNewSite'));
const SubLayoutOldSite = dynamic(() => import('./SubLayoutOldSite'));

export const LayoutPropTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  settingsGlobal: PropTypes.object,
  title: PropTypes.string,
  seo: PropTypes.object,
  slug: PropTypes.string,
  includedLocales: PropTypes.arrayOf(PropTypes.string).isRequired
};

const removeLangFromHref = (pageHref: any) => {
  const { base, slug } = getUrlParts(pageHref);
  return [base, slug].join('/');
};

const getIncludedLocale = (locale: any) => {
  return locale === 'en-us' ? 'en-US' : locale;
};

function Layout({
  includedLocales,
  children,
  settingsGlobal,
  className,
  title = 'Impossible Foods',
  seo = {},
  slug
}: any) {
  const { lang } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const pageHref = seo?.canonical?.value || global.location?.href;
  const supportedLocales = keys(locales);
  const settings = settingsGlobal;

  const seoObj = cloneDeep(seo);
  if (seoObj) {
    if (
      !seoObj['og:image'] ||
      !seoObj['og:image'].value ||
      seoObj['og:image'].value.url === 'https:undefined'
    ) {
      seoObj['og:image'] = settings.defaultSeo['og:image'];
    }
  }
  const metatags = getSEO(seoObj);
  const { user } = useUser();
  const [cookies] = useCookies(['if_locale']);
  const initiated = useSelector((state: any) => state.app.initiated);
  const preferredLanguage = useSelector((state: any) => state.app.preferredLanguage);
  const prevPreferredLanguage = usePrevious(preferredLanguage);
  const {
    globalHeader,
    globalFooter,
    accountSubnavLinks,
    accountOperatorSubnavLinks,
    subnavToutImage,
    subnavToutLink,
    overrideFooterProps
  } = settings ?? {};

  const seoTitle = seo?.title || {};
  const pageTitle = seoTitle.value || title;

  React.useEffect(() => {
    if (user) dispatch(getSavedRecipes({ uid: user.uid }));
  }, [user]);

  React.useEffect(() => {
    if (!initiated) dispatch(initApp());
  }, [initiated]);

  React.useEffect(() => {
    if (
      !initiated ||
      !prevPreferredLanguage ||
      prevPreferredLanguage === preferredLanguage ||
      lang === preferredLanguage
    )
      return;

    // use root path because preferredLanguage is locale
    // and footer locale is lang

    const path = slug === 'home' ? '/' : `/${slug}`;
    const pathWithLocale = getPath({ path, locale: preferredLanguage });
    const rootPath = getPath({ path: '/', locale: preferredLanguage });
    const currentPath =
      router.locale && router.locale !== 'en-US' ? `/${router.locale}${path}` : path;

    if ((!cookies.if_locale || cookies.if_locale === rootPath) && pathWithLocale !== currentPath) {
      // Map the preferredLanguage to the locale used in the paths i.e 'en-CA' -> 'ca'
      if (
        includedLocales &&
        preferredLanguage &&
        includedLocales.includes(getPathLocale(preferredLanguage))
      ) {
        router.push(pathWithLocale, pathWithLocale, { locale: false });
      }
    }
  }, [prevPreferredLanguage, preferredLanguage]);

  const fontUrl = '/newsite/fonts';

  return (
    <ErrorBoundary>
      <Container data-testid="Layout" className={cx(styles.layout)}>
        <Head>
          <title>{pageTitle}</title>
          {metatags.map((tag) => (
            <meta key={tag.name || tag.property} {...tag} />
          ))}
          <meta name="contentful_space" content={process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID} />
          <meta name="contentful_environment" content={process.env.NEXT_PUBLIC_CONTENTFUL_ENV} />
          <meta name="theme-color" content="#FFFFFF" />
          <link rel="canonical" href={pageHref} />
          <link rel="alternate" href={removeLangFromHref(pageHref)} hrefLang="x-default" />
          {supportedLocales.map((locale) => {
            const { slug: pageSlug } = getUrlParts(pageHref);
            return (
              (includedLocales && locale && includedLocales[getIncludedLocale(locale)]) ||
              []
            ).some((included: any) => included === pageSlug) ? (
              <link
                rel="alternate"
                href={getPageHrefByLocale(locale, pageHref, lang)}
                hrefLang={getHrefLang(locale, true)}
              />
            ) : null;
          })}
          <link rel="manifest" href="/newsite/manifest.webmanifest" />
          <link rel="icon" href="/newsite/favicon/icon-32x32.png" type="image/png" />
          <link rel="apple-touch-icon" sizes="48x48" href="/newsite/favicon/icon-48x48.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/newsite/favicon/icon-72x72.png" />
          <link rel="apple-touch-icon" sizes="96x96" href="/newsite/favicon/icon-96x96.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/newsite/favicon/icon-144x144.png" />
          <link rel="apple-touch-icon" sizes="192x192" href="/newsite/favicon/icon-192x192.png" />
          <link rel="apple-touch-icon" sizes="256x256" href="/newsite/favicon/icon-256x256.png" />
          <link rel="apple-touch-icon" sizes="384x384" href="/newsite/favicon/icon-384x384.png" />
          <link rel="apple-touch-icon" sizes="512x512" href="/newsite/favicon/icon-512x512.png" />

          {router?.asPath !== '/' || global.location?.pathname !== '/' ? (
            <>
              <link
                rel="preload"
                href={`${fontUrl}/sansmeat-compressed-bold.woff2`}
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
              />

              <link
                rel="preload"
                href={`${fontUrl}/sansmeat-wide-regular.woff2`}
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
              />
            </>
          ) : null}
        </Head>

        {accountSubnavLinks ||
        accountOperatorSubnavLinks ||
        subnavToutImage ||
        subnavToutLink ||
        settings ? (
          <GlobalHeader
            data-testid="GlobalHeader"
            accountSubnavLinks={accountSubnavLinks}
            accountOperatorSubnavLinks={accountOperatorSubnavLinks}
            subnavToutImage={subnavToutImage}
            subnavToutLink={subnavToutLink}
            globalSettings={settings}
            {...globalHeader}
          />
        ) : null}

        <main
          data-testid="Main"
          className={cx(styles.main, className)}
          id="maincontent"
          tabIndex={-1}>
          {slug === 'mm-homepage' ? (
            <SubLayoutNewSite>{children}</SubLayoutNewSite>
          ) : (
            <SubLayoutOldSite>{children}</SubLayoutOldSite>
          )}
        </main>

        {globalFooter ? (
          <GlobalFooter
            data-testid="GlobalFooter"
            {...globalFooter}
            includedLocales={includedLocales}
          />
        ) : null}

        {overrideFooterProps ? <ContentModule {...overrideFooterProps} /> : null}
      </Container>
    </ErrorBoundary>
  );
}

const fadeIn = keyframes`
    0% {
      opacity: 0;

    }

    100% {
      opacity: 1;

    }
`;

const Container = styled.div`
  p,
  h1,
  h2,
  h3,
  h4,
  button,
  span,
  a,
  em,
  strong {
    ${({ fontLoaded }: any) =>
      !fontLoaded
        ? css`
            /* opacity: 0; */
            /* animation: 0.3s ease-in-out forwards ${fadeIn}; */
            /* animation-delay: 0.5; */
          `
        : css`
            opacity: 1;
          `}
  }
`;

Layout.propTypes = LayoutPropTypes;

Layout.defaultProps = {
  className: '',
  settingsGlobal: null,
  title: 'Impossible Foods',
  seo: null,
  slug: ''
};

export default Layout;
