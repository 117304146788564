import React, {
  type ReactElement,
  createContext,
  useContext,
  useMemo,
  type PropsWithChildren,
  useState
} from 'react';
import { type Palette } from '../../types/Palette';

type PaletteState = {
  palette: Palette;
  setPalette(palette: Palette): void;
};

/**
 * PaletteProvider is a context provider for theme palette.
 * It provides a way to set and get the current palette across the application.
 * It also sets the palette as a data attribute on the body element.
 *
 * Usage:
 *
 * // Wrap your component tree with PaletteProvider and pass an initial palette
 * <PaletteProvider palette='palette-1'>
 *   <App />
 * </PaletteProvider>
 *
 * // In any child component, you can get or set the palette using the `usePaletteContext` hook
 * import { usePaletteContext } from './PaletteProvider';
 *
 * function ChildComponent() {
 *   const { palette, setPalette } = usePaletteContext();
 *
 *   // Use the palette
 *   console.log(palette);
 *
 *   // Set a new palette
 *   setPalette('palette-2');
 * }
 *
 * Note: If you try to use `usePaletteContext` outside `PaletteProvider`, it will throw an error.
 */

const PaletteContext = createContext<PaletteState | undefined>(undefined);

export function usePaletteContext(): PaletteState {
  const context = useContext(PaletteContext);
  if (!context) {
    throw new Error('usePaletteContext must be used within a PaletteProvider');
  }
  return context;
}

type PaletteProviderProps = Omit<PaletteState, 'setPalette'>;

export function PaletteProvider({
  palette: initialPalette,
  children
}: PropsWithChildren<PaletteProviderProps>): ReactElement {
  const [palette, setPalette] = useState(initialPalette);
  const paletteState = useMemo(() => ({ palette, setPalette }), [palette]);

  return (
    <PaletteContext.Provider value={paletteState}>
      <div data-palette={palette}>{children}</div>
    </PaletteContext.Provider>
  );
}
