/* eslint-disable camelcase */
// import _ from 'lodash';
import axios from 'axios';
import { GET_SAVED_RECIPES, TOGGLE_BOOKMARK_RECIPE, GET_PUBLISHED_RECIPES } from './recipes.types';

const baseUrl = process.env.NEXT_PUBLIC_CLOUDFUNCTIONS_BASE_URL;

export const getSavedRecipes = ({ uid }) => {
  return async (dispatch) => {
    let bookmarked;
    try {
      const { data } = await axios.get(`${baseUrl}/loyalty/recipes/${uid}/bookmarked`);
      const { savedRecipes } = data[0] || {};
      bookmarked = savedRecipes || [];
    } catch (err) {
      bookmarked = [];
    }

    return dispatch({
      type: GET_SAVED_RECIPES,
      payload: {
        bookmarked
      }
    });
  };
};

// TODO: understand this endpoint
export const getPublishedRecipes = () => {
  return async (dispatch) => {
    let published;
    try {
      const { data } = await axios.get(`${baseUrl}/recipes/?status=PUBLISHED`);
      published = data;
    } catch (err) {
      published = [];
    }
    return dispatch({
      type: GET_PUBLISHED_RECIPES,
      payload: {
        published
      }
    });
  };
};
