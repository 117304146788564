const getLocale = (locale) => {
  if (typeof locale === 'object') {
    const { country, language } = locale;
    if (country === 'CA' && language === 'fr-CA') return 'fr-CA';
    if (country === 'CA') return 'en-CA';
    if (country === 'AE') return 'en-AE';
    if (country === 'NZ') return 'en-NZ';
    if (country === 'GB') return 'en-GB';
    if (country === 'AU') return 'en-AU';
    if (country === 'HK' && language === 'zh-hk') return 'zh-Hant-HK';
    if (country === 'HK') return 'en-HK';
    if (country === 'CN' && language && language.includes('en')) return 'cn-en';
    if (country === 'CN') return 'cn';
    return 'en-US';
  }

  if (locale === 'ca') return 'en-CA';
  if (locale === 'hk') return 'zh-Hant-HK';
  if (locale === 'hk-en') return 'en-HK';
  if (locale === 'cn-en') return 'en-ZW';
  if (locale === 'cn') return 'zh-CN';
  if (locale === 'fr-ca') return 'fr-CA';
  if (locale === 'me-en') return 'en-AE';
  if (locale === 'nz-en') return 'en-NZ';
  if (locale === 'au-en') return 'en-AU';
  if (locale === 'gb-en') return 'en-GB';
  return 'en-US';
};

export default getLocale;
