export const mapping = {
  'ca': 'en-ca',
  'cn': 'cn',
  'cn-en': 'en-cn',
  'en-us': 'x-default',
  'fr-ca': 'fr-ca',
  'hk': 'hk',
  'hk-en': 'en-hk',
  'me-en': 'en-ae',
  'nz-en': 'en-nz',
  'gb-en': 'en-gb',
  'au-en': 'en-au'
};

export const validMapping = {
  'ca': 'en-ca',
  'cn': 'zh-cn',
  'cn-en': 'en-zw',
  'en-us': 'en-us',
  'fr-ca': 'fr-ca',
  'hk': 'zh-hk',
  'hk-en': 'en-hk',
  'me-en': 'en-ae',
  'nz-en': 'en-nz',
  'au-en': 'en-au'
};

const getHrefLang = (lang, getValidHreflangCode) => {
  const langLower = lang.toLowerCase();
  const langMapping = getValidHreflangCode ? validMapping : mapping;
  return langMapping[langLower] || lang;
};

export default getHrefLang;
