const languageMapping = {
  'en-CA': 'ca',
  'fr-CA': 'fr-ca',
  'en-AE': 'me-en',
  'en-NZ': 'nz-en',
  'en-AU': 'au-en',
  'en-GB': 'gb-en',
  'zh-Hant-HK': 'hk',
  'en-HK': 'hk-en',
  'en-US': 'en-US',
  'zh-CN': 'cn',
  'en-ZW': 'cn-en'
};

const getPathLocale = (lang) => {
  return languageMapping[lang] || lang;
};

export default getPathLocale;
