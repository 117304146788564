import dynamic from 'next/dynamic';

const Grid = dynamic(() => import('./legacy/components/Grid'));
const GlobalFooter = dynamic(() => import('./legacy/components/GlobalFooter'));
const ElementCard = dynamic(() => import('./legacy/components/ElementCard'));
const ElementLink = dynamic(() => import('./legacy/components/ElementLink'));
const ElementCardList = dynamic(
  () => import('./legacy/components/ElementCardList/ElementCardList')
);
const PageGeneral = dynamic(() => import('./legacy/components/PageGeneral'));
const StandardHero = dynamic(() => import('./legacy/components/StandardHero'));
const Section = dynamic(() => import('./legacy/components/Section'));

const ModuleText = dynamic(() => import('./legacy/components/ModuleText'));
const PageBlog = dynamic(() => import('./legacy/components/PageBlog'));

const ModuleIntegration = dynamic(() => import('./legacy/components/ModuleIntegration'));
const PageRecipe = dynamic(() => import('./legacy/components/PageRecipe'));
const CtaHero = dynamic(() => import('./legacy/components/CtaHero'));
const StatsHero = dynamic(() => import('./legacy/components/StatsHero'));
// const Layout = dynamic(() => import("./legacy/components/Layout"));
const ModuleHorizontal = dynamic(() => import('./legacy/components/ModuleHorizontal'));
const BannerFullSize = dynamic(() => import('./legacy/components/BannerFullSize'));
const ModuleTextWithSubheaders = dynamic(
  () => import('./legacy/components/ModuleTextWithSubheaders')
);
const ModuleSelectableUnitGroup = dynamic(
  () => import('./legacy/components/ModuleSelectableUnitGroup')
);

const PageNews = dynamic(() => import('./legacy/components/PageNews'));
const ModuleSelectableUnit = dynamic(() => import('./legacy/components/ModuleSelectableUnit'));
const PagePerson = dynamic(() => import('./legacy/components/PagePerson'));
const GlobalHeader = dynamic(() => import('./legacy/components/GlobalHeader'));
const ModuleHubspotForm = dynamic(() => import('./legacy/components/ModuleHubspotForm'));

const SelectableUnitGroupCarousel = dynamic(
  () => import('./legacy/components-v2/SelectableUnitGroupCarousel')
);
const ModuleForm = dynamic(() => import('./legacy/components/ModuleForm'));
const Accordion = dynamic(() => import('./legacy/components-v2/Accordion'));
const ElementCardListAccordion = dynamic(
  () => import('./legacy/components/ElementCardList/Accordion')
);
const Tabs = dynamic(() => import('./legacy/components-v2/Tabs'));
const Media = dynamic(() => import('./legacy/components-v2/Media'));
const JobListings = dynamic(() => import('./legacy/components/JobListings'));
// const Calculator = dynamic(() => import('./legacy/components/Calculator'));
const ModuleIFrame = dynamic(() => import('./legacy/components/ModuleIFrame'));
const ShopLocator = dynamic(() => import('./legacy/components/ShopLocator'));
const BackToTop = dynamic(() => import('./legacy/components-v2/BackToTop'));
const AllScienceNoBull = dynamic(() => import('./legacy/components/AllScienceNoBull'));
const Pixlee = dynamic(() => import('./legacy/components/Pixlee'));
const BazaarVoice = dynamic(() => import('./legacy/components/BazaarVoice'), {
  ssr: false
});
const Locator = dynamic(() => import('./legacy/components/Locator'));
const DestiniLocator = dynamic(() => import('./legacy/components/DestiniLocator'));
const DsarForm = dynamic(() => import('./legacy/components/DsarForm'));
const RecallForm = dynamic(() => import('./legacy/components/RecallForm'));

const FourPerRow = dynamic(() => import('./legacy/components/ElementCardList/FourPerRow'));
const MultiPerRow = dynamic(() => import('./legacy/components-v2/MultiPerRow'));
const ThreeOneThreeRows = dynamic(
  () => import('./legacy/components/ElementCardList/ThreeOneThreeRows')
);
const TwoPerRow = dynamic(() => import('./legacy/components/ElementCardList/TwoPerRow'));
const ThreePerRow = dynamic(() => import('./legacy/components/ElementCardList/ThreePerRow'));
const RowsWithFilter = dynamic(() => import('./legacy/components-v2/RowsWithFilter'));
const Timeline = dynamic(() => import('./legacy/components/ElementCardList/Timeline'));
const TwoOneUnit = dynamic(() => import('./legacy/components/ElementCardList/TwoOneUnit'));
const Showcase = dynamic(() => import('./legacy/components/ElementCardList/Showcase'));
const OnePerRow = dynamic(() => import('./legacy/components/ElementCardList/OnePerRow'));
const Carousel = dynamic(() => import('./legacy/components-v2/Carousel'));

const LazyInlineMap = dynamic(() => import('./legacy/components/InlineMap/LazyInlineMap'), {
  ssr: false
});

const ShopifyProductPage = dynamic(() => import('./legacy/components/PageShopifyProduct'));

const MMPage = dynamic(() => import('./components/core/Page'));
const C101Hero = dynamic(() =>
  import('./new-site/components/cms-components/c101-hero/C101Hero').then((mod) => mod.C101Hero)
);
const C102TabbedCarousel = dynamic(() =>
  import('./new-site/components/cms-components/c102-tabbed-carousel/C102TabbedCarousel').then(
    (mod) => mod.C102TabbedCarousel
  )
);
const C103Recipes = dynamic(() =>
  import('./new-site/components/cms-components/c103-recipes/C103Recipes').then(
    (mod) => mod.C103Recipes
  )
);
const C108TextCtaBlock = dynamic(() =>
  import('./new-site/components/cms-components/c108-text-cta-block/C108TextCtaBlock').then(
    (mod) => mod.C108TextCtaBlock
  )
);
const C104VerticalTab = dynamic(() =>
  import('./new-site/components/cms-components/c104-vertical-tab/C104VerticalTab').then(
    (mod) => mod.C104VerticalTab
  )
);
const C105Testimonials = dynamic(() =>
  import('./new-site/components/cms-components/c105-testimonials/C105Testimonials').then(
    (mod) => mod.C105Testimonials
  )
);
const C106Stories = dynamic(() =>
  import('./new-site/components/cms-components/c106-stories/C106Stories').then(
    (mod) => mod.C106Stories
  )
);
const C107Accordion = dynamic(() =>
  import('./new-site/components/cms-components/c107-accordion/C107Accordion').then(
    (mod) => mod.C107Accordion
  )
);
const C109StoreFinder = dynamic(() =>
  import('./new-site/components/cms-components/c109-store-finder/C109StoreFinder').then(
    (mod) => mod.C109StoreFinder
  )
);
const C110NewsletterSubscription = dynamic(() =>
  import(
    './new-site/components/cms-components/c110-newsletter-subscription/C110NewsletterSubscription'
  ).then((mod) => mod.C110NewsletterSubscription)
);

const C111Footer = dynamic(() =>
  import('./new-site/components/cms-components/c111-footer/C111Footer').then(
    (mod) => mod.C111Footer
  )
);

const M101DynamicTextStack = dynamic(() =>
  import('./new-site/components/molecules/m101-dynamic-text-stack/M101DynamicTextStack').then(
    (mod) => mod.M101DynamicTextStack
  )
);

const mapping = {
  'aaShopifyProduct': ShopifyProductPage,
  'section': Section,
  'grid': Grid,
  'globalFooter': GlobalFooter,
  'card': ElementCard,
  'elementLink': ElementLink,
  'pageGeneral': PageGeneral,
  'mmPage': MMPage,
  'cardList': ElementCardList,
  'moduleText': ModuleText,
  'pageBlog': PageBlog,
  'standardHero': StandardHero,
  'moduleIntegration': ModuleIntegration,
  'moduleForm': ModuleForm,
  'pageRecipe': PageRecipe,
  'ctaHero': CtaHero,
  'aaStatsHero': StatsHero,
  // settingsGlobal: Layout,
  'moduleHorizontal': ModuleHorizontal,
  'bannerFullSize': BannerFullSize,
  'moduleInlineLocator': LazyInlineMap,
  'moduleTextWithSubheaders': ModuleTextWithSubheaders,
  'moduleSelectableUnitGroup': ModuleSelectableUnitGroup,
  'pageNews': PageNews,
  'moduleSelectableUnit': ModuleSelectableUnit,
  'pagePerson': PagePerson,
  'globalHeader': GlobalHeader,
  'moduleHubspotForm': ModuleHubspotForm,
  'media': Media,
  'collection:Tabs': Tabs,
  'accordion': Accordion,
  'moduleSelectableUnitGroup:Carousel': SelectableUnitGroupCarousel,
  'moduleIntegration:jobListings': JobListings,
  'moduleIntegration:moduleIFrame': ModuleIFrame,
  'moduleIntegration:bazaarVoice': BazaarVoice,
  'moduleIntegration:shopLocator': ShopLocator,

  'moduleIntegration:pixlee': Pixlee,
  'moduleIntegration:backToTop': BackToTop,
  'moduleIntegration:locator': Locator,
  'moduleIntegration:destiniLocator': DestiniLocator,
  'moduleIntegration:recallForm': RecallForm,
  'moduleIntegration:dsarForm': DsarForm,
  'moduleIntegration:AllScienceNoBull': AllScienceNoBull,
  'cardList:Accordion Grouped by Year': ElementCardListAccordion,
  'cardList:1 per row': OnePerRow,
  'cardList:2 per row': TwoPerRow,
  'cardList:3 per row': ThreePerRow,
  'cardList:4 per row': FourPerRow,
  'cardList:5 per row': MultiPerRow,
  'cardList:6 per row': MultiPerRow,
  'cardList:2-1 unit': TwoOneUnit,
  'cardList:3-1-3 rows': ThreeOneThreeRows,
  'cardList:Carousel': Carousel,
  'cardList:Timeline': Timeline,
  'cardList:Showcase': Showcase,
  'cardList:Rows With Filter': RowsWithFilter,
  C101Hero,
  C102TabbedCarousel,
  'redesignCollection:carousel': C102TabbedCarousel,
  'redesignCollection:automaticTabbedCarousel': C102TabbedCarousel,
  'redesignTabs:horizontal': C103Recipes,
  'redesignTabs:vertical': C104VerticalTab,
  'redesignTabs': C104VerticalTab,
  'redesignAccordion': C107Accordion,
  'redesignBlock:storeFinder': C109StoreFinder,
  'moduleIntegration:subscribe': C110NewsletterSubscription,
  'redesignBlock': C108TextCtaBlock,
  'redesignStackedText': M101DynamicTextStack,
  C104VerticalTab,
  C105Testimonials,
  C106Stories,
  C107Accordion,
  C109StoreFinder,
  C110NewsletterSubscription,
  C111Footer
};

export default mapping;
