'use client';
/* eslint-disable react/forbid-prop-types */
import React, { createContext, useContext, useEffect } from 'react';

import dynamic from 'next/dynamic';
import { CookiesProvider, useCookies } from 'react-cookie';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ContentModuleContextProvider } from '../legacy/components/ContentModule';
import theme from '../legacy/theme/mui-theme';
import { AuthContextProvider } from '../legacy/components-v2/AuthenticatePage';
import I18nProvider from 'next-translate/I18nProvider';
import i18nConfig from './i18n.json';
import ThemeRegistry from '../components/core/ThemeRegistry/ThemeRegistry';
import wrapper from '../legacy/store'; // Import the Redux store

import { QueryClientProvider, QueryClient } from 'react-query';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const CartProvider = dynamic(() => import('../legacy/components/Cart/Cart.context'));
const TransitionPresence = dynamic(() =>
  import('@mediamonks/react-kit').then((module) => module.TransitionPresence)
);

declare global {
  interface Window {
    Osano: any;
  }
}

const AppProviderContext = createContext<{ contentGroup?: string }>({});

export const useAppProvider = () => {
  return useContext(AppProviderContext);
};
const queryClient = new QueryClient();
export const AppProvider = wrapper.withRedux(function AppProvider(props: {
  children: React.ReactElement;
  contentGroup?: string;
  pageProps?: any;
  inlineRecaptcha?: boolean;
  __lang?: string;
  __namespaces?: { [key: string]: any };
}) {
  const [cookieConsent, setCookie] = useCookies(['if_cookie_consent']);
  const { inlineRecaptcha = true } = props;
  useEffect(() => {
    const ifCookieConsent = cookieConsent.if_cookie_consent;
    if (!ifCookieConsent && window) {
      window.localStorage.removeItem('osano_consentmanager');
      window.Osano?.cm?.showDialog();
      setCookie('if_cookie_consent', Date.now(), { maxAge: 1_209_600 }); // Remove after 2 weeks
    } else {
    }
  }, [cookieConsent]);

  return (
    <AppProviderContext.Provider
      value={{
        contentGroup: props.contentGroup
      }}>
      <I18nProvider
        lang={props.pageProps?.__lang || props.__lang || i18nConfig.defaultLocale}
        namespaces={props.pageProps?.__namespaces || props.__namespaces}
        config={i18nConfig}>
        <GoogleReCaptchaProvider
          key={props.pageProps?.id}
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY!}
          useEnterprise
          container={
            inlineRecaptcha
              ? {
                  element: 'google-recaptcha-container',
                  parameters: {
                    badge: 'inline'
                  }
                }
              : undefined
          }>
          {/* <SmoothScroller key="app" duration={1.6}> */}
          <TransitionPresence>
            <ThemeRegistry>
              <MuiThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                  <CartProvider>
                    <ContentModuleContextProvider>
                      <CookiesProvider>
                        <AuthContextProvider>
                          {/* <CssBaseline /> */}
                          {props.children}
                        </AuthContextProvider>
                      </CookiesProvider>
                    </ContentModuleContextProvider>
                  </CartProvider>
                </QueryClientProvider>
              </MuiThemeProvider>
            </ThemeRegistry>
          </TransitionPresence>
          {/* </SmoothScroller> */}
        </GoogleReCaptchaProvider>
      </I18nProvider>
    </AppProviderContext.Provider>
  );
});
