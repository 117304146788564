/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import getLocale from '../../../utils/getLocale';
import { METRICS } from '../../../utils/constants';
import { INIT_APP_REQUEST, INIT_APP_SUCCESS, INIT_APP_FAILURE } from './app.types';

const baseUrl = process.env.NEXT_PUBLIC_CLOUDFUNCTIONS_BASE_URL;

// TODO: Determine inferredUserType
// Ref: if-website/src/dynamic-pages/PageSkull.js
// https://github.com/Impossible-Foods/if-analytics
const gtmSession = () => {
  if (!window.dataLayer.some((o) => o.event === METRICS.DL_READY)) {
    window.dataLayer.push({
      event: METRICS.DL_READY
    });
  }
};

export const initApp = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_APP_REQUEST
    });
    const language = navigator && navigator.language;

    try {
      window.dataLayer = window.dataLayer || [];
      if (window && window.dataLayer) {
        gtmSession();
      }
    } catch (err) {
      return dispatch({
        type: INIT_APP_FAILURE,
        payload: {
          error: err.message
        }
      });
    }

    try {
      const { data } = await axios.get(`${baseUrl}/user_locale`);
      const preferredLanguage = getLocale({ country: data.country, language });
      return dispatch({
        type: INIT_APP_SUCCESS,
        payload: { locale: data, navigator: { language }, preferredLanguage }
      });
    } catch (err) {
      return dispatch({
        type: INIT_APP_FAILURE,
        payload: {
          error: err.message
        }
      });
    }
  };
};
