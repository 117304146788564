/* ====================
  Base / Variables
  ===================== */
const theme = {
  /* Color palette */
  palette: {
    // Style Guide + Storybook
    // https://storybook.impossiblefoods.com/?path=/story/0-creative-brand-colors--page
    'blue': '#4F0423',
    'green': '#00dda5',
    'yellow': '#ffcd00',
    'peach': '#ffa589',
    'pink': '#4F0423',
    'dark-pink': '#ef3b5f',
    'burgundy': '#870d4f',
    'purple': '#a582e3',
    'gray': '#e6e6dc',

    // New repo
    'black': '#000',
    'white': '#fff',
    'near-black': '#260212',
    'light-blue': '#09f',
    'red': 'pink',
    'grey': '#e5e5e5',
    'light-grey': '#f6f6f6',
    'extra-light-grey': '#f9f9f9',
    'medium-grey': '#8f8f8f',
    'dark-grey': '#4f0423',
    'off-white': '#f0f0f0',
    'green-alt': '#00dea6',
    'purple-alt': '#c9f',
    'light-purple': '#dfb9ff',

    // Old repo
    'alabaster': '#f7f7f7',
    'alto': '#d0d0d0',
    'amethyst': '#a44fcf',
    'blue-ribbon': '#0058ff',
    'bon-jour': '#e5e0e1',
    'bright-turquoise': '#07cbff',
    'bastille': '#201c28',
    'boulder': '#7d7d7d',
    'cararra': '#e6e6dc',
    'cardinal': '#bb203b',
    'caribbean-green': '#00dea6',
    'caribbean-green-opaque': '#00ce9a',
    'concrete': '#f2f2f2',
    'cotton-candy': '#ffb6d5',
    'cyan': '#0ff',
    'dark-charcoal': '#333',
    'dark-gray': '#434343',
    'dove-gray': '#696969',
    'dusty-gray': '#9a9999',
    'eyebrow': '#8f8f8f',
    'flamingo': '#f26522',
    'ford-gray': '#979797',
    'fuel-yellow': '#f1ad27',
    'gallery': '#eee',
    'ghost': '#cdcdd5',
    'jade': '#00a279',
    'light-gray': '#dadada',
    'mercury': '#e5e5e5',
    'mine-shaft': '#260212',
    'mint-tulip': '#beeff1',
    'mona-lisa': '#ffa58a',
    'moon-mist': '#e0e2d6',
    'pattens-blue': '#dcf4ff',
    'persian-green': '#00b285',
    'pink-lace': '#ffd1f2',
    'purple-darken': '#9b74e0',
    'radical-red': '#4F0423',
    'radical-red-darken': '#ff2c50',
    'rose-bud-cherry': '#870d4f',
    'rose': '#f00074', // Instagram
    'san-marino': '#3b63a6', // Facebook
    'silver': '#ccc',
    'silver-chalice': '#a1a1a1',
    'silver-chalice-dark': '#a4a4a4',
    'royal-blue': 'blue',
    'silver-light': '#cbcbcb',
    'solitude': '#ebf8ff',
    'spring-wood': '#f6f7f1',
    'supernova': '#ffcd00',
    'transparent': 'transparent',
    'tuna': '#4f0423',
    'white-approx': '#fefefe',
    'wild-sand': '#f6f6f6',
    'wild-sand-solid': '#ffc7c6',
    'persimmon': '#ff6150',
    'alert-success-bg': 'rgba(233, 251, 247, 095)',
    'alert-danger-bg': 'rgba(255, 235, 234, 095)',
    'alert-warning-bg': 'rgba(255, 248, 225, 095)',
    'alert-warning-border': '#fde18b',
    'alert-info-bg': 'rgba(220, 243, 247, 095)',
    'alert-info-border': '#ade2ea',
    'alert-primary-bg': 'rgba(224, 227, 255, 095)',
    'alert-primary-border': '#c5caf9',
    'alert-secondary-bg': 'rgba(238, 238, 238, 095)',
    'alert-secondary-border': '#d0d2d7'
  },

  // Fonts
  fonts: {
    'headline': 'SansMeatCompressedBold',
    'subhead': 'SansMeatCompressedBold',
    'standard': 'SansMeatProportional',
    'standardBold': 'SansMeatProportionalBold',
    'scienceHeadline': '"SansMeatCompressedBold", sans-serif',
    'scienceSubhead': '"SansMeatCompressedBold", sans-serif',
    'standard-bold': '"SansMeatCompressedBold", sans-serif',
    'headline-alt': '"SansMeatCompressedBold", sans-serif',
    'subhead-alt': '"SansMeatCompressedBold", sans-serif'
  }
};

export default theme;
