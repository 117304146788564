// Import `createAsyncThunk` from Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import getLocale from '../../../utils/getLocale';

const baseUrl = process.env.NEXT_PUBLIC_CLOUDFUNCTIONS_BASE_URL;

// Define the `initApp` async thunk
export const initApp = createAsyncThunk('app/initApp', async (_, { rejectWithValue }) => {
  try {
    window.dataLayer = window.dataLayer || [];
    const language = navigator && navigator.language;
    const { data } = await axios.get(`${baseUrl}/user_locale`);
    const preferredLanguage = getLocale({ country: data.country, language });

    // Assuming `gtmSession` doesn't need to be awaited or doesn't return a promise
    gtmSession();

    return { locale: data, navigator: { language }, preferredLanguage };
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

// appSlice definition remains the same
const appSlice = createSlice({
  name: 'app',
  initialState: {
    loading: false,
    initiated: false,
    locale: {},
    navigator: {},
    preferredLanguage: 'en-US',
    error: undefined
  },
  reducers: {
    // Reducers for other non-async actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      .addCase(initApp.pending, (state) => {
        state.loading = true;
      })
      .addCase(initApp.fulfilled, (state, action) => {
        const { locale, navigator, preferredLanguage } = action.payload;
        state.loading = false;
        state.initiated = true;
        state.locale = locale;
        state.navigator = navigator;
        state.preferredLanguage = preferredLanguage;
      })
      .addCase(initApp.rejected, (state, action) => {
        state.loading = false;
        state.initiated = false;
        state.error = action.payload;
      });
  }
});

export const { initAppRequest, initAppSuccess, initAppFailure } = appSlice.actions;
export default appSlice.reducer;
