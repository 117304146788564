import isPlainObject from 'lodash/isPlainObject';

const getArgs = (args) => args.filter((arg) => isPlainObject(arg));

const getOptions = (args) => args.reduce((a, b) => ({ ...b, ...a }), {});

const isOGSimple = (option) => option.name.startsWith('og:') && typeof option.value === 'string';

const isOGComplex = (option) =>
  option.name.startsWith('og:') && isPlainObject(option.value) && option.value.url;

const isTwitter = (option) =>
  option.name.startsWith('twitter:') && typeof option.value === 'string';

const isTwitterComplex = (option) =>
  option.name.startsWith('twitter:') && isPlainObject(option.value) && option.value.url;

const isComplex = (option) => isPlainObject(option.value) && option.value.url;

const isValidOption = (option) => option && option.name && option.value;

const getTags = (options) =>
  Object.keys(options).reduce((acc, key) => {
    if (!isValidOption(options[key])) return acc;
    if (isOGSimple(options[key]))
      acc.push({ property: options[key].name, content: options[key].value });
    else if (isOGComplex(options[key]))
      acc.push({
        property: options[key].name,
        content: options[key].value.url
      });
    else if (isTwitter(options[key]))
      acc.push({ name: options[key].name, content: options[key].value });
    else if (isTwitterComplex(options[key]))
      acc.push({ name: options[key].name, content: options[key].value.url });
    else if (isComplex(options[key]))
      acc.push({ name: options[key].name, content: options[key].value.url });
    else acc.push({ name: options[key].name, content: options[key].value });
    return acc;
  }, []);

const getSEO = (...args) => {
  const validArgs = getArgs(args);
  const options = getOptions(validArgs);
  const tags = getTags(options);
  return tags;
};

export default getSEO;
