import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import createSpacing from '@material-ui/core/styles/createSpacing';
import constants from './index';

const spacing = createSpacing(8);
const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 684,
    md: 768,
    lg: 1024,
    xl: 1440
  }
});

const headerStyles = {
  fontFamily: constants.fonts['headline-alt'],
  textTransform: 'uppercase',
  fontWeight: 700
};

const theme = createTheme({
  // Config
  breakpoints,
  spacing: 8,

  // Typography
  // https://storybook.impossiblefoods.com/?path=/story/0-creative-brand-typography--page
  typography: {
    fontFamily: constants.fonts.standard,
    fontSize: 16,
    h1: { ...headerStyles, fontWeight: 700 },
    h2: { ...headerStyles, fontWeight: 700 },
    h3: {
      ...headerStyles,
      fontSize: 70,
      lineHeight: 1,
      // letterSpacing: spacing(0.06),
      padding: 0,
      fontWeight: 700,

      [breakpoints.down('xs')]: {
        fontSize: 37
      }
    },
    h4: {
      ...headerStyles,
      fontSize: 46,
      // letterSpacing: spacing(0.04),
      lineHeight: 1,
      fontWeight: 700,

      [breakpoints.down('xs')]: {
        fontSize: 37
      }
    },
    h5: { ...headerStyles, fontFamily: constants.fonts.subhead, fontWeight: 700 },
    h6: {
      ...headerStyles,
      fontFamily: constants.fonts.subhead,
      fontSize: 31,
      fontWeight: 700,
      // letterSpacing: spacing(0.025),
      lineHeight: 1
    },
    body1: {
      color: 'black',
      fontFamily: constants.fonts.standard,
      fontSize: 16,
      lineHeight: 1.56,
      fontWeight: 400
    },
    subtitle1: {
      fontSize: 22,
      lineHeight: 1,
      fontWeight: 700
      // letterSpacing: spacing(0.02)
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: 1.35,
      fontWeight: 700,
      fontFamily: constants.fonts.standard
    }
  },
  palette: {
    background: {
      default: '#FFF'
    },
    primary: {
      main: constants.palette.blue
    },
    extra: {
      ...constants.palette
    },
    blue: constants.palette.blue,
    black: constants.palette.black,
    white: constants.palette.white
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '7.8125rem',
        lineHeight: '6.75rem'
      },
      h2: {
        fontSize: '2.3125rem',
        lineHeight: '2.3125rem'
      }
    },
    MuiContainer: {
      root: {
        [breakpoints.up('sm')]: {
          paddingLeft: spacing(7.5),
          paddingRight: spacing(7.5)
        },
        paddingLeft: spacing(2),
        paddingRight: spacing(2)
      }
    },
    MuiChip: {
      root: {
        height: 26
      },
      label: {
        fontFamily: constants.fonts.standard,
        fontSize: 14,
        lineHeight: 1.36
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiInput-root"] $input': {
          marginBottom: spacing(1)
        }
      }
    },
    MuiTextField: {
      root: {
        width: '100%'
      }
    },
    MuiFormControlLabel: {
      root: {
        alignItems: 'flex-start',
        marginRight: 0,
        marginBottom: spacing(3.75)
      },
      label: {
        marginTop: spacing(0.5),
        fontSize: 12
      }
    },
    MuiButton: {
      root: {
        outline: 'none !important',
        fontFamily: constants.fonts.subhead
      },
      outlined: {
        'border': `2px solid ${constants.palette.blue}`,
        'borderWidth': '2px !important',
        'borderRadius': 0,
        'padding': spacing(1.5, 6.25),
        'fontSize': 25,
        'lineHeight': 1.15,

        '&:hover': {
          borderWidth: '2px !important'
        }
      },
      contained: {
        'border': `2px solid ${constants.palette.blue}`,
        'borderWidth': '2px !important',
        'borderRadius': 0,
        'padding': spacing(1.5, 6.25),
        'fontSize': 25,
        'lineHeight': 1.15,

        '&:hover': {
          borderWidth: '2px !important'
        }
      }
    },
    MuiInput: {
      root: {
        marginBottom: spacing(3.75)
      },

      input: {
        fontSize: 16,
        padding: 0
      },

      underline: {
        // 'height': 47,
        'padding': spacing(1.75, 0),

        '&:before': {
          borderBottomColor: constants.fonts.tuna
        }
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  },
  shadows: ['none', '0 3px 13px rgba(0, 0, 0, 0.16)'],
  components: {
    ImageCarousel: {
      styleOverrides: {
        root: {
          minHeight: 500
        }
      }
    }
  }
});

export default theme;
