// store/store.js
import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import appReducer from './modules/app/appSlice';

const makeStore = () =>
  configureStore({
    reducer: {
      app: appReducer
    }
  });

export default createWrapper(makeStore);
